@import "../../styles/utils/index";

.container {
  position: relative;
  @include flex-centered(true, true);
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 540px;
  width: 100%;
  z-index: 40;
  margin-bottom: 1rem;
}

.canvasBox {
  position: relative;
  padding: 0;
  margin: calc($topbar-height-mobile - 120px) 0 0;
  left: 0;
  width: 325px;
  height: 325px;

  @media screen and (max-width: 375px) {
    height: 300px;
  }

  @include is-tablet-portrait {
    width: 525px;
    height: 400px;
    margin: calc($topbar-height-mobile - 170px) 0 0;
  }

  @media screen and (max-height: 800px) and (min-width: 500px)  {
    width: 325px;
    height: 325px;
    margin: 35dvh 0 0;
  }
}

.spinButtonContainer {
  position: relative;
  height: 48px;
  width: 100%;
  max-width: 340px;

  @include is-tablet-portrait {
    height: 63px;
    max-width: 420px;
  }
}

.motionWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.spinButton {
  width: 100%;
  max-width: 340px;
  height: 50px;
  margin: 0;
  font-size: 20px;
  background-image: url("../../assets/svg/button-big.svg");
  background-repeat: no-repeat;
  background-size: cover;
  text-shadow: $button-text-shadow;

  .spinText {
    margin-top: -9px;
  }

  @include is-tablet-portrait {
    max-width: 440px;
    height: 58px;

    .spinText {
      margin-top: -6px;
    }
  }
}

.resultBox {
  @include flex-centered(true, true);
  width: 100%;
  position: relative;
  text-align: center;

  .textBox {
    @include flex-centered(true, true);
    text-align: center;

    h1 {
      max-width: 220px;
      margin: 0;
    }

    .resultText {
      max-width: 200px;
      margin: 0;
      text-transform: uppercase;
      color: $light-text-color;
    }
  }

  .bottomBlock {
    @include flex-centered(true, true);
    width: 100%;
  }
}

.skipButton {
  text-shadow: $button-text-shadow;
  text-decoration: underline;
  opacity: 1;
  transition: all 0.2s ease-in-out;

  &:disabled {
    text-shadow: none;
    color: $color-white-transparent;
  }
}

.hidden {
  opacity: 0;
}

.visible {
  opacity: 1;
}
