@import "src/styles/utils";

.settings {
  @include flex-centered(true, true);
  position: relative;
  width: 100%;
  max-width: 360px;

  button {
    width: 118px;
    font-family: var(--root-font-family);
    font-weight: 700;

    @include is-tablet-portrait {
      width: 145px;
      height: 60px;
    }

    @include is-desktop-large {
      width: 178px;
      height: 65px;
    }
  }

  .payButton {
    width: 100%;
    height: 53px;
    margin: 0 0 0;
    text-shadow: $button-text-shadow;
    background-image: url("../../assets/svg/button-big.svg");
    background-repeat: no-repeat;
    background-size: cover;
    transition: all 0.2s ease-in-out;

    span {
      font-size: 22px;
      line-height: 30px;
      margin-top: -7px;
    }

    @include is-tablet-portrait {
      max-width: 440px;
      height: 58px;
    }

    @include is-desktop-large {
      max-width: 540px;
      height: 72px;
    }
  }

  @include is-tablet-portrait {
    max-width: 440px;
  }

  @include is-desktop-large {
    max-width: 540px;
  }
}

.breakBox {
  width: 100%;
  height: 25px;
  max-width: 350px;
  @include flex-centered(false, true);

  .divider {
    width: 15%;
    height: 1px;
    background-color: #DAA48F;
  }

  .textBlock {
    font-size: 9px;
    text-transform: uppercase;
    color: $light-text-color;
    flex-grow: 1;
    text-align: center;

    @include is-tablet-portrait {
      font-size: 10px;
    }

    @include is-desktop-large {
      font-size: 11px;
    }
  }

  @include is-tablet-portrait {
    max-width: 540px;
  }
}

.actionBoard {
  @include flex-centered(false, false);
  position: relative;
  height: 4.7rem;
  width: 100%;
  max-width: 365px;

  @include is-tablet-portrait {
    max-width: 510px;
    height: 4.3rem;
  }

  @include is-desktop-large {
    max-width: 540px;
    height: 4.8rem;
  }
}

.bottomBlock {
  @include flex-centered(false, true);
  position: relative;
  width: 100%;
  height: 72px;
  align-items: flex-start;

  @include is-tablet-portrait {
    max-width: 540px;
  }
}
