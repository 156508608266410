@import "src/styles/utils";

.wrapper {
  @include flex-centered(true, false);
  justify-content: space-between;
  position: absolute;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  width: 100%;
  height: $topbar-height;
  background: $topbar-background;

  .cloud {
    position: absolute;
    top: 10%;
    width: 20vw;
    max-width: 450px;
    z-index: inherit;
  }

  &.game,
  &.multiplier,
  &.endGame {
    height: var(--topbar-height-mobile-game);
    background: $topbar-background-desktop;
    overflow: hidden;

    &::before {
      content: "";
      position: relative;
      width: 100%;
      height: 100%;
      background-image: url("../../assets/background/background-city.png");
      background-size: contain;
      background-repeat: repeat-x;
      background-position: center bottom;
      opacity: 1;
      z-index: 1;
    }

    @include is-tablet-portrait {
      height: $topbar-height-tablet;
      background: $topbar-background-desktop;

      &::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        background-image: url("../../assets/background/background-city.png");
        background-repeat: repeat-x;
        background-size: contain;
        opacity: 1;
      }
    }
  }

  @include is-tablet-portrait {
    height: $topbar-height-tablet;
    background: $topbar-background-desktop;
    overflow: hidden;

    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      background-image: url("../../assets/background/background-city.png");
      background-size: contain;
      opacity: 1;
    }
  }

  @include is-desktop {
    border-radius: 0;
    height: $topbar-height-small-destop;
  }

  @include is-desktop-large {
    height: $topbar-height-desktop;
  }
}

.demoTag {
  @include flex-centered(true, true);
  position: absolute;
  top: 0;
  left: 15%;
  width: 56px;
  height: 29px;
  background-image: url("../../assets/elements/demo-tag.png");
  background-size: contain;
  background-repeat: no-repeat;
  text-transform: uppercase;
  font-weight: 500;
  z-index: 300;

  @include is-tablet-portrait {
    left: 10%;
  }

  @include is-desktop {
    left: 18%;
  }

  @media screen and (min-width: 2050px) {
    left: 25%;
  }
}

.topInnerWrapper {
  position: absolute;
  @include flex-centered;
  top: 7px;
  z-index: 200;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;

  .progressTable {
    position: absolute;
    right: 0;
    top: 3.5rem;

    &.hasMultiplier {
      top: 2.5rem;
    }

    @include is-tablet-portrait {
      top: 4rem;
      text-align: right;

      &.hasMultiplier {
        top: 3.5rem;
      }
    }

    @include is-desktop {
      top: 4.6rem;

      @media screen and (max-height: 800px) {
        top: 4rem;
      }
    }
  }

  .gameSoundIcon {
    @include flex-centered;

    position: relative;

    width: 32px;
    height: 32px;

    background: var(--top-menu-buttons-background);
    border: none;
    border-radius: 100%;

    @include is-tablet-portrait {
      width: 40px;
      height: 40px;
    }
  }

  .logo {
    position: absolute;
    left: 50%;
    height: 68px;
    border: none;
    outline: none;
    transform: translateX(-50%);
    z-index: 300;

    @include is-tablet-portrait {
      height: 90px;
    }

    @include is-desktop-large {
      height: 100px;
    }
  }

  .menuButton {
    @include flex-centered(true, true);
    padding: 5px;
    margin: 5px;
    width: 30px;
    height: 30px;
    cursor: pointer;
    background: $menu-button-background;
    border: none;
    border-radius: 8px;

    @include is-tablet-portrait {
      width: 40px;
      height: 40px;
    }

    @include is-desktop {
      left: 30px;
    }
  }

  .soundIcon {
    height: 15px;
    width: 15px;
    animation: fade-in 0.3s ease forwards;

    @include is-tablet-portrait {
      height: 25px;
      width: 25px;
    }
  }

  @include is-tablet-portrait {
    height: $topbar-height;
    margin: 0 auto;
  }

  @include is-desktop {
    max-width: 1420px;
    margin: 0 auto;
    padding-top: 10px;

    @media screen and (max-height: 800px) {
      padding-top: 0;
    }
  }

  @include is-desktop-large {
    padding-top: 15px;
  }
}

.startHeader {
  @include flex-centered(true, true);
  position: absolute;
  text-align: center;
  top: 55%;
  z-index: 10;
  width: 100%;
  max-width: 280px;

  h1 {
    margin: auto;
  }

  .multiplierHeaderBox,
  .gameHeaderBox {
    @include flex-centered(true, true);
    max-width: 280px;
    text-align: center;
    color: $light-text-color;

    .winText,
    .noWinText {
      font-size: 13px;
      margin: 0;
      text-transform: uppercase;
      max-width: 230px;
    }

    .winText {
      max-width: 140px;
    }

    @include is-tablet-portrait {
      max-width: 400px;
    }
  }

  .gameHeaderBox {
    margin-top: 2.8rem;

    h1 {
      max-width: 250px;

      @include is-tablet-portrait {
        max-width: 370px;
      }
    }
  }

  &.isGame {
    @include is-tablet-portrait {
      top: 40%;
    }
  }

  &.isMultiplier {
    top: 45%;
  }

  @include is-tablet-portrait {
    top: 28%;
    max-width: 450px;
  }

  @include is-desktop-large {
    top: 25%;
  }
}
